import React, { useState } from 'react';
import {Link, redirect} from 'react-router-dom'
import styled from "styled-components";
import Load from './loader.js';
import Download from "../images/KRL - DOWNLOAD.png"
import axios from 'axios';
import Header from '../components/header.js';
import authService from '../services/auth.services.js';
import { Navigate } from 'react-router-dom';
import Fundo from '../images/Fundo.png';
import pasta from '../images/pasta.png'

const Resprem_download_styled = styled.div`
    background-image: url("${Fundo}");
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .box1 {
        background-color: #000;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 75px;
        width: 100%; 
    }

    .img {
        width: 150px;
        height: 150px;
         
    }      

    p {
        color: #B3B3B3;
        font-size: 20px;
        font-family:Poppins;  
        align-items:center;
    }

    .concluido  img{
        width: 50px;
        height: 50px;
    }

    .iconeDownload{
        width:200px;
        height:200px;  
    }

    .botaoDownload{
        background-color: #ff6600;
        color:#fff;
        width: 150px;
        height:40px;
        border-radius:10px ; 
        margin-left: 5px;
        cursor: pointer;
        border:none;
        font-size:15px;
        font-weight:600;
    }

    #body{
    width:80%;

    }
    
    .botaoCancelar{
        background-color:#d3d3d3;
        color: #fff;
        width: 150px;
        height:40px;
        border-radius:10px ;
        cursor: pointer;
        border:none;
        font-size:15px;
        font-weight:600;
    }

    .fundo {
        background-color: #fff;
        height: 560px;
        width: 100%;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        animation: 2.5s cubic-bezier(.25, 1, .30, 1) wipe-cinematic-in both;
    }

    @keyframes wipe-cinematic-in {
        0% {
            clip-path: inset(100% 0 100% 0);
        }
        30%, 70% {
            clip-path: inset(10% 0 10% 0);
        }
        100% {
            clip-path: inset(0 0 0 0);
        }
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .concluido{
        display: flex;
        flex-direction: row;
    }
          #header{
        margin-top:5%;
        
        
        }
      
`;

class Resprem_download extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            redirectTo: null
        }
    }

    async componentDidMount(){
        let loggedUser = await authService.getLoggedUser()
        if(!loggedUser){
            console.log("Redirecionou", loggedUser)
            this.setState({redirectTo: true})
        }

    }

    async Zip(){
        try {
            const idUser = authService.getIdUser();
            // No servidor
            const response = await axios.get('https://api.krltech.com.br/download/Arquivos Gerados.zip', {
              responseType: 'blob',
              headers: {
                'User-ID': idUser
            }
            });

            // No local
            // const response = await axios.get('/download/Arquivos Gerados.zip', {
            //     responseType: 'blob',
            //     headers: {
            //       'User-ID': idUser
            //   }
            //  });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Arquivos Gerados.zip');
            document.body.appendChild(link);
            link.click();
          } catch (error) {
            console.error('Erro ao baixar o arquivo:', error);
          }
    }

    closeLoad = () => this.setState(!this.state.load)

    render() {
        
        if (this.state.redirectTo){
            return(
                <Navigate to="/"/>
            )
        }
        return(
            <Resprem_download_styled>
            {this.state.load && <Load active={this.closeLoad}/>}
            <div id="body">
                <div id="header">
            <Header/>
                </div>       
            <div class="fundo">
                <div class="concluido">
                    <p>Quebra de arquivo concluida com sucesso! </p>
                </div>
                    <img src ={pasta} alt=" iconeDownload" class="iconeDownload"/>
                <div class ="botaum">
                    <Link to={'/index'}><button class="botaoCancelar" id = "botaoCancelar" type ="button" value="botao">Cancelar</button></Link>
                    <button class="botaoDownload" id = "botaoDownload" type ="button" value="botao" onClick={this.Zip}>Download</button>
                </div>     
            </div>
            </div>
        </Resprem_download_styled>
        )
    }

}

// const Resprem_download = () => {
//     const [load, setLoad] = useState(true)

//     const closeLoad = () => setLoad(!load)

//   return(
//     <Resprem_download_styled>
//         {load && <Load active={closeLoad}/>}
//         <Header/>     
//         <div class="fundo">
//             <div class="concluido">
//                 <p>Quebra de arquivo concluida com sucesso! </p>
//                 <img src={Check} alt="concluido"/>
//             </div>
//                 <img src ={Download} alt=" iconeDownload" class="iconeDownload"/>
//             <div class ="botaum">
//                 <Link to={'/'}><button class="botaoCancelar" id = "botaoCancelar" type ="button" value="botao">Cancelar</button></Link>
//                 <button class="botaoDownload" id = "botaoDownload" type ="button" value="botao" onClick={Zip}>Download</button>
//             </div>     
//         </div>
//     </Resprem_download_styled>
//   )
// }

export default Resprem_download;