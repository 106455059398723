import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Index from './pages/menu.js'
import Resprem from './pages/tela-resprem.js'
import NotFound from './pages/notfound.js'
import Loading from './pages/loader.js'
import Resprem_download from './pages/tela-resprem-dowload.js'
import { ProgressBar } from './components/progress-bar.js';
import Login from './pages/login.js';

function App() {
  

  return (
    <BrowserRouter>
        <Routes>
            <Route path='/' element={<Login/>}/>
            <Route path='/index' element={<Index/>}/>
            <Route path='/resprem' element={<Resprem/>}/>
            <Route path='/resprem-download' element={<Resprem_download/>}/>
            <Route path='*' element={<NotFound/>}/>
            <Route path='/loading' element={<Loading/>}/>
            <Route path="/progress" element={<ProgressBar />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
