import React, { useState, useEffect } from 'react';
import User from "../images/3.png";
import Line from '../components/line.js';
import styled from "styled-components";
import authService from '../services/auth.services.js';
import { Navigate } from 'react-router-dom';
import Load from './loader.js';
import {Link} from 'react-router-dom'
import LineLink_styled from '../components/styled-components/lineLink.js';
import Fundo from "../images/Fundo.png"
import LogoKRL from "../images/9.png"

const Menu_styled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("${Fundo}");
    margin: 0;
    width: 100%;
    padding: 15px 0px;
    height: 44.5rem;

    h1{
        font-family: Montserrat;
        font-style: normal;
        color: #FF6600;
        margin-left: 70px; 
        margin-top: 50px;
    }

    .box{
        display: flex;
        flex-direction: column;
        background-color: white;
        width: 1200px;
        height: 35.7rem;
        overflow-y: scroll;
    }

    .box::-webkit-scrollbar-track{
        /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
        border-radius:10px;
        background-color: #D0D4CE;
    }

    .box::-webkit-scrollbar{
        width: 6px;
        border-radius:10px;
        background-color: #D0D4CE;
    }

    .box::-webkit-scrollbar-thumb{
        border-radius:10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #71806B;
    }

    .line{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .sbv{
        display: flex;
        align-items: center;
        background-color: white;
        width: 1200px;
        height: 60px;
        padding: 4px 0px;
        cursor: default;
    }
`;

const Cabecalho_styled = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #FF6600;
    height: 60px;
    width: 1200px;
    margin-top: 43px;

    .box1{
        display: flex;
        flex-direction: row; 
        align-items: center;
        justify-content: center;
        text-align: center;   
        font-family: Montserrat;
        font-style: normal;
        color: white;   
        margin-left: 10px;
        cursor: default;
    }

    button{
        border: none;
        background: none;
        cursor: pointer;
        color: white;
        font-family: Montserrat;
        font-style: normal;
        text-decoration: underline;
        margin-right: 10px;
    }

    h5{
        margin-left: 5px;
    }
`;

class Menu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            redirectTo: false,
            load: true,
            nome: "",
        }
    }

    async componentDidMount(){
        let loggedUser = await authService.getLoggedUser()
        let nomeUser = await authService.getNomeUser();
        let idUser = await authService.getIdUser();
        console.log(idUser)
        const nome = nomeUser
        if(!loggedUser){
            console.log("Redirecionou", loggedUser)
            this.setState({redirectTo: true})
        }
        this.setState({nome: nomeUser})
        console.log(nome)
    }



    render(){
        const closeLoad = () => this.setState(!this.state.load)
        if (this.state.redirectTo){
            return(
                <Navigate to="/"/>
            )
        }
        return(
            <Menu_styled>
                {this.state.load && <Load active={closeLoad}/>}

                <Cabecalho_styled> 
                    <div className='box1'>
                        <img src={User} width="auto" height="40px" />
                        <h4> {this.state.nome} </h4>  
                    </div>
            
                    <div className='box2'>
                        <img src={LogoKRL} width="135px" height="auto"/>
                    </div> 
            
                    <div>
                        <button onClick={authService.cleanLoggedUser}><h2>Sair</h2></button> 
                    </div>   
                </Cabecalho_styled>   

                
                <div className='container'>
                    <div className='sbv'><h1>Olá, seja bem-vindo!</h1></div>
                    <div className='box'>
                        <Link to={"/resprem"}>
                            <div className='line'>
                                <Line/> <LineLink_styled>  RESPREM  </LineLink_styled>
                            </div>
                        </Link>
                    </div>
                </div>
            </Menu_styled>
        )
    }
 }

 export default Menu;
