import authService from '../services/auth.services.js';

export default class Request {

    uploadFile = (file) => {
        const formData = new FormData()
        formData.append('file', file)
        const idUser = authService.getIdUser();

        // No servidor
        return fetch('https://api.krltech.com.br/resprem', {
        
            method: 'post',
            headers: {
                'User-ID': idUser,
            },
            body: formData,
        });

        // No Local:
        // return fetch('http://localhost:5000/resprem', {
        //     method: 'post',
        //     headers: {
        //         'User-ID': idUser,
        //     },
        //     body: formData,
        // });
        
    }


}