import React from 'react';
import styled from "styled-components";

const LineLink_styled = styled.div`
    
        font-family: Montserrat;
        font-style: normal;
        color: black;  
        font-weight: 600;
        font-size: 18px;
        text-decoration: underline;
      
`;

export default LineLink_styled;