import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import authService from '../services/auth.services.js';

const ProgressBar_styled = styled.div`
    width: 300px;
  .progress-bar{
    border: white solid 2px;
    border-radius: 12px;
    color: white;
  }
  .progress-bar-percentage{
    color: white;
    font-family: 'Montserrat';
    font-size:15px;
    margin-top: 5px;
  }

  .progress-bar-fill {
    height: 30px;
    border-radius: 10px;
    background-color: white;
    transition: width 0.3s ease-in-out; 
  }

  .progress-label {
    font-size: 25px;
    font-family: 'Montserrat';
    color: white;
    font-weight: bold;
  }

  .progress-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    border: white dashed 3px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    animation: loader-circle 2s linear infinite;
    margin-top: 5px;
  }

  .center{
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 5px;
  }
  .preparingcenter{
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
  }

  span {
    background-color:white;
    border-radius:100%;
    width:5px;
    height:5px;
    margin: 6px 2px;
    animation: loader-tres-pontinhos 2s infinite; 
  }

  @keyframes loader-tres-pontinhos{
    0% {
      transform: scale(.5);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
`;

export const ProgressBar = () => {
  const [progresso, setProgresso] = useState(0);
  const navigate = useNavigate();
  const [msg, set_msg] = useState('');

  const iniciarPorcentagem = async () => {
    try {
        const idUser = authService.getIdUser();
        if (!idUser) {
            throw new Error('ID do usuário não encontrado');
        }
        // No servidor
        const response = await fetch(`https://api.krltech.com.br/contagem?user_id=${idUser}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
      //  No local
      //   const response = await fetch(`/contagem?user_id=${idUser}`, {
      //     method: 'GET',
      //     headers: {
      //         'Content-Type': 'application/json'
      //     }
      // });

        if (!response.ok) {
            throw new Error(`Erro ao buscar dados: ${response.statusText}`);
        }

        const data = await response.json();

        const msg = data.mensagem;
        if (msg === 'ok') {
            set_msg(data.mensagem);
        }
        setProgresso(data.progresso);

    } catch (error) {
        console.error('Erro ao iniciar porcentagem:', error);
    }
  };

  useEffect(() => {
    const porcentagemInterval = setInterval(() => {
      iniciarPorcentagem();
    }, 6000);
  
    if (msg === 'ok') {
      navigate("/resprem-download");
      clearInterval(porcentagemInterval);
    }
  
    return () => clearInterval(porcentagemInterval);
  }, [msg]);

const preparingFile = () => {
  const fileZero = progresso;

  if(fileZero == 0) {
    return <ProgressBar_styled> <div className="preparingcenter">
      <div className="progress-label">Preparando arquivo</div> 
      <span></span>
      <span></span>
      <span></span>
    </div>
  </ProgressBar_styled>
  }else{
    return <ProgressBar_styled>
      <div className="center">
        <div className="progress-label">Carregando</div> 
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div className="center-column">
        <div className="progress-bar">
          <div className="progress-bar-fill" style={{ width: `${progresso}%` }}></div>
        </div>
        <div className="progress-bar-percentage"> {progresso}% </div>
      </div>
    </ProgressBar_styled>
  }
}
  return (
    preparingFile()
  );
};