import React, { useState } from 'react';
import styled from "styled-components";
import {Link, redirect} from 'react-router-dom'
import FileUpload from '../components/file-upload.js';
import Header from '../components/header.js';
import { Navigate } from "react-router-dom";
import axios from 'axios';
import authService from '../services/auth.services.js';
import Load from './loader.js';
import Fundo from "../images/Fundo.png"

const Resprem_styled = styled.div`
    background-image: url("${Fundo}");
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
    
    
    .box1 {
        background-color: #ffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 75px;
        width: 100%;
        
    }

    #body{
    width:80%;

    display: flex;
    flex-direction:column;

    }

    .img {
        width: 140px;
        height: 140px;
        margin-right: 10px; 
        
    }

  

    button {
        color: #fff;
        background-color: #ff6600;
        border-radius: 10px;
        padding: 10px 10px;
        margin-left: 20px;
        border: none;
        cursor: pointer;
        font-size:15px;
        font-weight:600;
    }
        #header{
        margin-top:5%;
        
        
        }
      

    @keyframes wipe-in-top-left {
        from {
            clip-path: polygon(150% 50%, 150% 50%, 50% 150%, 50% 150%);
        }
        to {
            clip-path: polygon(50% -50%, 150% 50%, 50% 150%, -50% 50%);
        }
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .title{
        color: red;
    }

`;

class Resprem extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            redirectTo: null,
            type: '',
            message: '',
            load: true,
            quebra: '',
        }
    }

    async componentDidMount(){
        let loggedUser = await authService.getLoggedUser()
        if(!loggedUser){
            console.log("Redirecionou", loggedUser)
            this.setState({redirectTo: true})
        }

    }

    changeScreen = async (data) => {
        try{
            // No Servidor:
            const response = await axios.get('https://api.krltech.com.br/resprem', data);

            // No Local:
            // const response = await axios.get('http://localhost:5000/resprem', data);

            if (response.data.message === "sucess"){    
                return <Navigate to="/resprem-download"/>
            }
            else{
                console.log('erro ao mudar de Tela')
            }
        } catch (error){
            console.log('Erro ao mudar de tela')
        }
    };

    render(){
        const closeLoad = () => this.setState(!this.state.load)
        if (this.state.redirectTo){
            return(
                <Navigate to="/"/>
            )
        }
        return(
            <Resprem_styled>
                {this.state.load && <Load active={closeLoad}/>}
                <div id="body">
                    <div id="header">
                        <Header/>
                    </div>
                    <FileUpload/>
                </div>
            </Resprem_styled>
        )

    }
}

export default Resprem;

