import React, { Component, useEffect } from 'react'
 import Dropzone from 'react-dropzone'
 import Pasta from "../images/1.png"
 import Fundo from "../images/Fundo.png"
 import LogoKRL from "../images/9.png"
 import styled from "styled-components";
 import Request from '../services/Request';
 import Popup from 'reactjs-popup';
import { ProgressBar } from './progress-bar';
import { Navigate, redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

 const FileUpload_styled = styled.div`
    background-color: #fff;
    width: 100%;
    height: 580px;
    animation: 10s cubic-bezier(.25, 1, .30, 1) wipe-in-top-left both;
    clip-path: polygon(150% 50%, 150% 50%, 50% 150%, 50% 150%);

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      

    .text-center{
    display: flex;
    align-items: center;
    justify-content: center;
      border-radius: 5px;
      cursor: pointer;
    }
    .resprem{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      margin-bottom: -50px;
    }

    .img-pasta{
        height:130px ;
        width: 130px;
    }

    h1{
        color: #000;
        font-size: 80px;
        font-family: 'Montserrat';
        justify-content: center;
    }

    p {
        color: #000;
        font-family: 'Montserrat';
        justify-content: center;
    }

    h4{
      font-family: 'Montserrat';  
      color: #A6A6A6;
    }

    .column{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .column-active{
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .resprem-active{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      margin-bottom: -50px;
    }

    .text-center-active{    
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 20% 40%;
      border: 3px dashed gray;
    }

    .App-intro {
      display:flex;
      align-items: center;
      color: white;
      font-size: 15px;
      font-family: 'Montserrat';
      justify-content: center;
      flex-direction: column;
    }
`;

 const StyledPopup = styled(Popup)`
  &-overlay {
    background-image: url("${Fundo}");
  }

  &-overlay img{
    width: 100px;
    height: auto;
  }

  &-content {
    width: 600px;
    height: 300px;
    font-size: 18px;
    text-align: center;
    padding: 0;
    border-radius: 20px;
    background-color: #FF6600;
    animation: 1s cubic-bezier(.25, 1, .30, 1) square-in-center both;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-content .mb-2{
    color: white;
        font-size: 15px;
        font-family: 'Montserrat';
  }

  @keyframes square-in-center {
    from {
      clip-path: inset(100% 100% 100% 100%);
    }
    to {
      clip-path: inset(0 0 0 0);
    }
  }

`;

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      targetFile: null,
      message: '',
      redirect: false,
      modal: false,
      fileLoaded: false 
    };
    this.request = new Request();
  }

  onDrop = files => {
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    this.setState({
      files,
      targetFile: files[0],
      reader,
      showImage: false,
      message: '',
      fileLoaded: true
    });
  };


  submit = () => {

    if (this.state.fileLoaded) {
      if (this.state.teste){
        toast.warning("Erro, por favor contate o administrador da plataforma")
        console.log('não está rodando')
      }else{
        this.setState({ teste: true})
        this.setState({ modal: true});
        console.log('ja está rodando')
        this.request.uploadFile(this.state.targetFile).then(response => {
          this.reponseJson(response.json());
          this.setState({redirect: true})
          }).catch(error => {
            this.reponseJson(error.json());
          });
      }
    } else {
      toast.warning("Arquivo obrigatório!");
      // alert('Arquivo obrigatório: Por favor, carregue um arquivo antes de prosseguir.');
    }
  };

  reponseJson = (response) => {
    response.then(result => {
      this.setState({ message: result.message, redirect: true })
    })
  }

    render() {  

      if(this.state.modal){
        return (
            <StyledPopup
              open={this.state.modal}
              on={'click'}
              modal
              nested
              closeOnDocumentClick={false}
              closeOnEscape={false}
            >
              <div className='logo'> <img src={LogoKRL} alt ="Logo" class = "img-logo"/> </div>
              <ProgressBar/>
            </StyledPopup>
        )
        
      }

      if (this.state.redirect) {
        return <Navigate to ="/resprem-download" />;
      }

    const file = this.state.files.map(file => (
      <li key={file.name}>
        {file.name}
      </li>
    )); 

    return (
      <div>
        <Dropzone onDrop={this.onDrop}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <FileUpload_styled>
                <div {...getRootProps()} >
                <input {...getInputProps()} /> 
                  <div className="text-center">
                    {isDragActive ? (
                      <div className="text-center-active">
                        <div className='column-active'>
                        <div className='resprem-active'>
                          <h1>Resprem</h1>
                          <img src={Pasta} alt ="Pasta" class = "img-pasta"/>
                        </div>
                          <h4> Solte o arquivo aqui </h4>
                        </div>
                        
                      </div>  
                    ) : (
                      <div className="text-center" >
                        <div className='column'>
                          <div className='resprem'>
                            <h1>Resprem</h1>
                            <img src={Pasta} alt ="Pasta" class = "img-pasta"/>
                          </div>
                          <h4> Clique para selecionar ou arraste o arquivo RESPREM aqui. </h4>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <p className="App-intro">
                  <button id="baixar" type="button" name="botao" value="pois" onClick={this.submit} >
                    Carregar
                  </button>
                  <p className='mb-2'>{file}</p>
                </p>
                

            </FileUpload_styled>
          )}
        </Dropzone>
        <ToastContainer draggable closeButton={false}/>
      </div>
    );
  }
}


export default FileUpload;