import React, { useState } from 'react';
import styled from "styled-components";
import Load from './loader.js';
import Logo from "../images/WOrangeIcon.png"

const NotFound_style = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    margin: 0;
    width: 100%;
    height: 740px;
    font-family: Montserrat;
    font-style: normal;
    color: white;

    img{
      width: 100px;
      height: auto;
    }

    h2{
      margin-top: -10px;
    }
`;

export default function NotFound() {
  const [load, setLoad] = useState(true)

  const closeLoad = () => setLoad(!load)
  return(
    <NotFound_style>
        {load && <Load active={closeLoad}/>}
        <img src={Logo} alt="Logo KRL" id="img2" /> 
        <h1>404 not found</h1>
        <h2>Entre em contato com o suporte.</h2>
    </NotFound_style>
  )
}