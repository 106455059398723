import React from 'react';
import styled from "styled-components";
import Logo2 from "../images/WOrangeIcon.png"


const Loader_styled = styled.div`
    margin: 0;
    width: 100%; 
    height: 100%; 
    background-color: black;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    animation: fadeOut 2s;
    animation-fill-mode: forwards;

    .container img{
    position: relative;
    animation: loading 5s linear infinite;
    }

    .container{
    width: auto; 
    height: 700px;
    max-height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    }

    img{
    width: 150px;
    height: auto;
    }
    
    @keyframes loading {
        0% {
            transform: rotate(10deg);
            width: 150px;
        }
        30%{
            width: 170px;
        }
        40%{
            width: 190px;
        }
        80%{
            width: 200px;
        }
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes fadeOut {
        100%{
            opacity: 0;
            visibility: hidden;
        }
    }
 
`;

class Loader extends React.Component{
    render(){
        return(
            <Loader_styled>
                <div class="container"> 
                    <div class="loader">
                        <img src={Logo2}/>
                    </div>
                </div>
            </Loader_styled>
          )
    }
  
}

export default Loader;