import React from "react";
import Header from "../components/header";
import Logo from "../images/WOrangeIcon.png"
import styled from "styled-components";
import authService from "../services/auth.services";
import { Navigate, redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Load from './loader.js';
import Fundo from "../images/Fundo.png"
import LogoMeio from "../images/WOrangePrincipal.png"
import cadeado from "../images/cadeado.png"
 
 
const Login_styled = styled.div`
    font-family: 'Montserrat', sans-serif;
    background-image: url("${Fundo}");
    background-size: cover;
    font-size: 1rem;
    min-height: 100vh;
 
 
*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
   
}
nav li{
    list-style-type: none;
}
 
 
a{
    color: #FF6600;
    font-family:montserrat;
}
#img2{
   margin-bottom: -30%;
    height: 290px;
    width: auto;
   
    border-radius:10px;
}
  .body{
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
 
}
.Login {
  Position: absolute;
  bottom: 70%;
 }
   
section{
   
    width: 38%;
    height: 430px;
    background-color: #ffff;
    border: 5px solid #ffffff;
    border-radius: 30px 30px 0px 0px;
    padding: 40px;
    Position: absolute;
    bottom: 0%;
 
}
input::placeholder{
  font-size: 18px;
}
#email{
  border: 3px solid #ff6600;
}
#email::placeholder{
  color: #ff6600;
}
.textEmail{
  color: #00000;
  text-align: left;
  margin-left: 10%;
  font-family: 'Montserrat', sans-serif;
  font-size: 17px;
}
#labelEmail{
  padding: 5px 0px 10px 0px;
  text-align: center;  
}
#password{
  padding-top: 25px;
  padding-left: 45px;
  background-image: url("${cadeado}");
  background-repeat: no-repeat;
  background-position: 8px;
  background-size: 40px;
}
    #labelPassword{
   
    text-align: center;  
   
}
#password::placeholder{
  text-align: left;
  color: black;
}
.header-container {
    background-color: rgb(0, 0, 0);
    padding: 1px 0;
    height: 100px;
}
section aside{
    width:21.12621359223301%;
    background-color: #CCC;
    position:absolute;
    top:0;
    right:0;
}
section aside a:hover{
    text-decoration: underline;
}
    label{
        width: 150px;
        display: inline-block;
    }
    input{
        width: 80%;
        height: 15%;
        border: #000000 3px solid;
        border-radius: 8px;
        padding: 20px;
        color: #000000;
        background-color: #ffffff;
        font-size: 17px;
       
    }
    button{
        width: 75%;
        height: 15%;
        border: #ff6600 1px solid;
        border-radius: 10px;
        padding: 25px;
        color: #ffffff;
        background-color: #ff6600;
        margin-bottom: 5%;
        font-size: 27px;
        font-weight: bolder;
    }
    fieldset{
        border-radius: 20px;
        border: none;  
    }
 
    .Enviar{
        margin-top: 5%;
        background-color: #FF6600;
        width: 80%;
        height: 15%;
        border-radius: 10px;
        color: #ffffff;
        font-family: 'Montserrat', sans-serif;
        display: flex;
        justify-content: center;
        margin-bottom: 5%;
        font-weight: bold;
    }
 
    .botoes{
      width: 100%;
      height: 15%;
      display: flex;
      justify-content: center;
    }
 
    .forgot{
      width: 100%;
      display: flex;
      justify-content: right;
      margin-top: 5%;
      margin-bottom: 3%;
     
    }
   
`;
 
 
class LoginPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
          email: "",
          password:"",
          redirectTo: false,
          load: true
        }
    }
 
    sendLogin = async (event) => {
      event.preventDefault();
      let data = {
        email: this.state.email,
        password: this.state.password
      }
      try{
        let res = await authService.authenticate(data)
        console.log("res", res.data)
        authService.setLoggedUser(res.data)
        this.setState({redirectTo: true})
      } catch (error){
        console.log(error)
        toast.error("Erro ao efetuar o login");
        // alert("Erro ao efetuar Login")
      }
    }
 
    render(){
      const closeLoad = () => this.setState(!this.state.load)
      if (this.state.redirectTo){
        return(
          <Navigate to="/index"/>
        )
      }
        return <Login_styled>
          {this.state.load && <Load active={closeLoad}/>}
       
        <div className="body">
           
           <div className="Login">
            <p><img src={LogoMeio} alt="Logo KRL" id="img2" /> </p>
            <br/>
            </div>
            <br/>
           
          <section>
          <br />
            <article>
             
              <form onSubmit={this.sendLogin}>
                <fieldset>
                 
                  <p className="textEmail">E-mail</p>
                  <p id="labelEmail">
                    <input
                      type="email"
                      id="email"
                      placeholder="usuario@krlservices.com.br"
                      value={this.state.email}
                      onChange={e => this.setState({email: e.target.value})}
                    />
                  </p>
                  <br/>
                  <p id="labelPassword">
                    <input
                     
                      id="password"
                      type="password"
                      placeholder= "Senha"
                      value={this.state.password}
                      onChange={e => this.setState({password: e.target.value})}
                    />
                  </p>
                </fieldset>
                <div className="forgot">
                  <p></p>
                </div>
                <br />
                <div className="botoes">
                  <button type="submit">LOGIN</button>
                </div>
              </form>
            </article>
          </section>
          <ToastContainer draggable closeButton={false} />
        </div>
      </Login_styled>
    }
}
 
export default LoginPage;