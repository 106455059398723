// Importanto biblioteca responsável por requisições HTTP
import axios from 'axios';

// Definindo a base do url para os endpoints
// No Servidor:
const apiUrl = "https://api.krltech.com.br";

// No Local:
// const apiUrl = "http://localhost:5000";

// Definindo o bjeto do serviço
const authService = {

    // Definindo a função de login
    async authenticate(data) {
        console.log(data)
        const endpoint = `${apiUrl}/`
        return axios.post(endpoint, data);
    },

    // Função para salar o usuário logado no local storage
    setLoggedUser(data){
        let parsedData = JSON.stringify(data)
        localStorage.setItem("user", parsedData)
    },

    // Função responsável por recuperar o usuário logado do local storage
    getLoggedUser(){
        let data = localStorage.getItem("user");
        if(!data) return null;
        try {
            let parsedData = JSON.parse(data)
            return parsedData
        } catch (error) {
            console.log(error)
            return null
        }
    },

    getNomeUser(){
        let data = localStorage.getItem("user");

        if(!data) return null;
        try {
            let parsedData = JSON.parse(data)
            let nome = parsedData.nome
            console.log(nome)
            return nome
        } catch (error) {
            console.log(error)
            return null
        }
    },

    getIdUser(){
        let data = localStorage.getItem("user");

        if (!data) return null;
        try {
            let parsedData = JSON.parse(data)
            let id = parsedData.id
            return id
        } catch (error) {
            console.log(error)
            return null
        }
        
    },

    // Função resposável para excluir o usuário no local storage
    cleanLoggedUser(){
        localStorage.clear()
        window.location.reload()
    }
}

export default authService;