import React from "react";
import styled from "styled-components";
import Logo from "../images/9.png";
import {Link} from 'react-router-dom';


const Header_styled = styled.div`
    background-color: #FF6600;
    display: flex;
    width: 100%;
    padding: 1px 0; 
    height: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div{
        margin-right: 2%;
    }
    img{
        height: 150px;
    }
`


const Header = () => {
    return (
        <Header_styled>
            <Link to={'/index'}>
                <div>
                    <img src={Logo} alt="logo" class="img"/>
                </div>
            </Link>    
        </Header_styled>
    )
}

export default Header;