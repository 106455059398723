import React from 'react';
import styled from "styled-components";
import Cursor from "../images/4.png";

const Line_styled = styled.div`
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center; 
        margin-left: 50px;

    .circle{
        display: flex;
        justify-content: center;
        align-items: center;
        
    }
`;

const Line = () => {
    return (
    <Line_styled >
        <div className='circle'>
            <img src={Cursor} width="150" height="auto"/>

        </div>
    </Line_styled>
    )
  }

  export default Line;